<template>
	<!-- 提现页面 -->
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="提现/转出" style="padding: 20px 10px;" />
		<div class="content">
			<div class="header">
				<h3>余额提现</h3>
				<i></i>
				<p>可提现金额：<span>{{money}}</span>元</p>
			</div>
			<div class="recharge-form">
				<div class="form-item">
					<p class="form-item_label">提现金额：</p>
					<input type="text" v-model="form.money" @keyup="handleMoneyChange">元
				</div>
				<slot name="tag1" />
				<div class="form-item">
					<p class="form-item_label">提现到：</p>
					<div class="form-checkbox_wrap">
						<label class="form-checkbox_label">
							<input type="radio" checked />
							<div class="form-checkbox_box">
								<div class="form-checkbox_box_image xx"></div>
								<p class="form-checkbox_box_text">线下汇款</p>
							</div>
						</label>
					</div>
				</div>
				<div>
					<div class="form-item">
						<p class="form-item_label">开户地区：</p>
						<area-select v-model="selected" :data="pcaa" type="text" :level="2" @change="getSite" />
					</div>
					<div class="form-item">
						<p class="form-item_label">开户行名称：</p>
						<input type="text" v-model="form.khh">
					</div>
					<div class="form-item">
						<p class="form-item_label">对公账号：</p>
						<input type="text" v-model="form.card_no">
					</div>
				</div>
				<div class="warning">温馨提示：<br />1.受银行处理时间影响，采用线下对公方式到账会有所延误。<br />2.提现完成后，您可以进入余额收支明细页面进行查看提现状态。</div>
				<button class="form-btn" @click="handleSubmit" :disabled="form.money*1>money*1">提现</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		pca,
		pcaa
	} from 'area-data'
	import 'vue-area-linkage/dist/index.css'
	export default {
		data() {
			return {
				money: 0,
				pca,
				pcaa,
				selected: [],
				form: {
					money: '',
					type: 3,
					province: '',
					city: '',
					county: '',
					khh: '',
					card_no: ''
				}
			}
		},
		created() {
			this.$axios.post('/cash').then(data => {
				this.money = data
			})
		},
		methods: {
			handleMoneyChange() {
				this.form.money = this.form.money.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
			},
			getSite(data) {
				this.form.province = data[0];
				this.form.city = data[1];
				this.form.county = data[2];
			},
			handleSubmit() {
				const form = this.form
				if (form.money == '') {
					this.$message.warning('请填写提现金额');
					return
				}
				if (form.province == '') {
					this.$message.warning('请选择开户地区');
					return
				}
				if (form.khh == '') {
					this.$message.warning('请填写开户行名称')
					return
				}
				if (form.card_no == '') {
					this.$message.warning('请填写对公账号')
					return
				}
				this.$axios.post('/cash', form).then(data => {
					this.$message.success('申请成功')
					this.$router.push('/assets')
				})
			}
		}
	}
</script>

<style scoped>
	.content {
		border: 1px solid #f4f4f4;
		border-radius: 5px;
		padding-bottom: 49px;
	}

	.header {
		height: 59px;
		display: flex;
		align-items: center;
		padding: 0 23px;
		color: #333;
		border-bottom: 1px solid #f4f4f4;
	}

	.header h3 {
		font-size: 18px;
	}

	.header i {
		width: 1px;
		height: 18px;
		background: #666666;
		margin: 0 15px 0 18px;
	}

	.header p {
		font-size: 14px;
	}

	.header span {
		font-size: 20px;
		color: #fd0436;
	}

	.recharge-form {
		font-size: 14px;
		color: #333;
		margin-top: 11px;
	}

	.form-item {
		display: flex;
		align-items: center;
		margin-top: 26px;
	}

	.form-item_label {
		width: 100px;
		text-align: right;
	}

	.form-item input[type=text] {
		width: 189px;
		height: 38px;
		border: 1px solid #cbcbcb;
		margin-right: 4px;
		text-indent: 10px;
	}

	.form-tag_1 {
		color: #999;
		margin-top: 12px;
		margin-left: 102px;
	}

	.form-checkbox_wrap {
		display: flex;
	}

	.form-checkbox_label {
		width: 278px;
		height: 79px;
		position: relative;
		margin-right: 22px;
	}

	.form-checkbox_label input {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.form-checkbox_box {
		width: 276px;
		height: 77px;
		border: 1px solid #e3e6ec;
		display: flex;
	}

	.form-checkbox_box_image {
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 22px;
		background-size: 50px;
	}

	.form-checkbox_box_image.ye {
		background: url(../../assets/img/ye.png);

	}

	.form-checkbox_box_image.wx {
		background: url(../../assets/img/wx.png);
	}

	.form-checkbox_box_image.zfb {
		background: url(../../assets/img/zfb.png);
	}

	.form-checkbox_box_image.xx {
		background: url(../../assets/img/yinlian.png) no-repeat;
		width: 68px;
		height: 43px;
		background-size: 68px 43px;
	}

	.form-checkbox_box_image.xx+p {
		left: 102px;
	}

	.form-checkbox_box_text {
		font-size: 18px;
		color: #333333;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 89px;
	}

	.form-checkbox_label input:checked+div {
		border: 1px solid #fd0436;
	}

	.form-checkbox_label input:checked+div::after {
		content: '';
		position: absolute;
		width: 54px;
		height: 31px;
		right: 0;
		bottom: 0;
		background: url(../../assets/img/file-checkbox.png);
		background-size: 54px 31px;
	}

	.form-tag_2 {
		font-size: 12px;
		color: #fd0436;
		margin-top: 13px;
		margin-left: 102px;
	}

	.warning {
		font-size: 12px;
		color: #666666;
		line-height: 22px;
		padding: 14px 22px;
		border: 1px solid #ffbb76;
		background: #fffcef;
		width: 946px;
		box-sizing: border-box;
		margin-left: 32px;
		margin-top: 22px;
	}

	.form-btn {
		width: 155px;
		height: 40px;
		border-radius: 5px;
		background: #117df5;
		text-align: center;
		line-height: 40px;
		color: #fff;
		margin-top: 42px;
		margin-left: 32px;
		border: none;
	}

	.form-btn:disabled {
		background: #D9D9D9;
	}
</style>
